<template>
  <section>
    <app-bar />
    <navigation-drawer />

    <v-main>
      <transition-page>
        <router-view :key="$route.path" />
      </transition-page>
    </v-main>
  </section>
</template>

<script>
import TransitionPage from "@/components/Layout/TransitionPage";
import AppBar from "@/components/Layout/AppBar";
import NavigationDrawer from "@/components/Layout/NavigationDrawer";

export default {
  name: "layout",
  components: {
    TransitionPage,
    AppBar,
    NavigationDrawer,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "Layout";
</style>
