<template>
  <v-app-bar
    flat
    app
    absolute
    height="72"
    extension-height="26"
    color="transparent"
    class="px-1"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mdAndDown"
      @click.stop="drawer()"
      :ripple="false"
    >
      <v-icon size="38" color="transparent">$bars</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title>
      <div class="font-medium-20 gray13--text pt-5">
        {{ $t(drawerItemName) }}
      </div>
      <!--      <v-breadcrumbs :items="breadcrumbItems" class="mt-2 mb-3" />-->
      <BreadcrumbCustom :data="paths" :language="'en'" pathAfter="1" />
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BreadcrumbCustom from "@/components/Layout/Breadcdrumb/BreadcrumbCustom";

export default {
  name: "app-bar",
  components: { BreadcrumbCustom },
  props: {},
  data() {
    return {
      paths: {
        "/panel/dashboard": { en: "Dashboard" },
        "/panel/dashboard/withdrawal": { en: "Withdrawal" },
        "/panel/transactions": { en: "Transactions" },
        "/panel/transactions/deposit-details": { en: "-" },
        "/panel/transactions/deposit-details/?": {
          en: "Cash in details",
        },
        "/panel/transactions/withdrawal-details": { en: "-" },
        "/panel/transactions/withdrawal-details/?": {
          en: "Cash out details",
        },
        "/panel/transactions/refund-details": { en: "-" },
        "/panel/transactions/refund-details/?": {
          en: "Refund details",
        },
        "/panel/businesses": { en: "Businesses" },
        "/panel/businesses/create": {
          en: "Create business",
        },
        "/panel/businesses/web-gateway-settings": { en: "-" },
        "/panel/businesses/web-gateway-settings/?": {
          en: "Web gateway settings",
        },
        "/panel/businesses/pos-gateway-settings": { en: "-" },
        "/panel/businesses/pos-gateway-settings/?": {
          en: "POS gateway settings",
        },
        "/panel/businesses/?": { en: "-" },
        "/panel/businesses/?/details": {
          en: "Business details",
        },
        "/panel/businesses/?/create-gateway": {
          en: "Create gateway",
        },
        "/panel/businesses/?/create-web-gateway": {
          en: "Create web gateway",
        },
        "/panel/businesses/?/create-pos-gateway": {
          en: "Create pos gateway",
        },
        "/panel/businesses/?/create-web-gateway-report": {
          en: "Create web gateway report",
        },
        "/panel/businesses/?/create-pos-gateway-report": {
          en: "Create pos gateway report",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      drawerState: "drawer/GET_DRAWER",
    }),
    toggledDrawerState() {
      return this.drawerState;
    },
    drawerItemName() {
      return this.$route.meta.appBarTitle;
    },
    // breadcrumbItems() {
    //   const fullPath = this.$route.fullPath;
    //   let params = fullPath.startsWith("/")
    //     ? fullPath.substring(1).split("/")
    //     : fullPath.split("/");
    //   const crumbs = [];
    //   let path = "";
    //   params.forEach((param, index) => {
    //     path = `${path}/${param}`;
    //     const match = this.$router.match(path);
    //     if (match.name === null) {
    //       param = this.$t(this.drawerItemName);
    //       crumbs[0].text = this.$t(
    //         "panel.businessesPage.businessesList.breadcrumbBaseTitle"
    //       );
    //     }
    //     if (path !== fullPath && index !== 0) {
    //       crumbs.push({
    //         href: path,
    //         text: param.charAt(0).toUpperCase() + param.slice(1),
    //         disabled: index === params.length - 2,
    //       });
    //     }
    //   });
    //   return crumbs;
    //   // let pathArray = this.$route.path.split("/");
    //   // pathArray.shift();
    //   // return pathArray.reduce((breadcrumbArray, path, index) => {
    //   //   if (index > 1) {
    //   //   console.log(1111, this.$route.matched[index + 2].name)
    //   //   breadcrumbArray.push({
    //   //     path: path,
    //   //     to: breadcrumbArray[index - 1]
    //   //       ? "/panel" + "/" + breadcrumbArray[index - 1].path + "/" + path
    //   //       : "/panel" + "/" + path,
    //   //     text:
    //   //       this.$route.matched[index + 2].name.charAt(0).toUpperCase() +
    //   //         this.$route.matched[index + 2].name.slice(1) || path,
    //   //   });
    //   //   }
    //   //
    //   //   return breadcrumbArray;
    //   // }, []);
    // },
  },
  mounted() {},
  methods: {
    ...mapActions({
      drawerAction: "drawer/ACTION_DRAWER",
    }),
    drawer() {
      this.drawerAction(!this.toggledDrawerState);
    },
  },
};
</script>

<style scoped lang="scss">
@import "AppBar";
</style>
