<template>
  <div
    :class="['breadcrumb-route dynamic-breadcrumb', { hide: !route.length }]"
  >
    <TransitionGroup
      :name="dir === 'rtl' ? 'routeFarsi' : 'routeEnglish'"
      tag="ul"
      :class="['breadcrumb-items-block', { 'rtl-direction': dir === 'rtl' }]"
    >
      <li
        v-for="(path, index) in pathNames"
        :key="index"
        class="breadcrumb-item"
      >
        <template v-if="data && show">
          <router-link
            v-if="findPath(route[index]) !== '-'"
            :separator="separator"
            class="link-item"
            :to="{ path: route[index] }"
          >
            {{ findPath(route[index]) || path }}
          </router-link>
        </template>
        <router-link
          v-else-if="show"
          :separator="separator"
          class="link-item"
          :to="{ path: route[index] }"
        >
          {{ path }}
        </router-link>
      </li>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  name: "breadcrumb-custom-vue",
  components: {},
  props: {
    pathAfter: {
      type: String,
      default: "0",
    },
    separator: {
      type: String,
      default: "/",
    },
    dir: {
      type: String,
      default: "",
    },
    language: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      obj: {},
      arr: [],
      route: [],
      show: true,
    };
  },
  computed: {
    pathNames() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.route = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.arr = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.obj = {};
      let currentPathNames = decodeURI(this.$route.path);
      if (this.data) {
        if (this.data["/"]) {
          currentPathNames = currentPathNames.slice(0);
        } else {
          currentPathNames = currentPathNames.slice(1);
        }
      } else {
        currentPathNames = currentPathNames.slice(1);
      }
      let pathArray = currentPathNames.split("/");
      let separatePath = "";
      pathArray.forEach((item, index, self) => {
        self[index] = item.charAt(0).toUpperCase() + item.slice(1);
        separatePath = separatePath + "/" + item;
        separatePath = separatePath.replace("//", "/");
        this.route.push(separatePath);
      });
      if (pathArray[0] === "" && pathArray[1] === "") {
        pathArray.splice(1, 1);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.route.splice(1, 1);
      }
      if (this.pathAfter > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        pathArray = pathArray.slice(this.pathAfter);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.route = this.route.slice(this.pathAfter);
      }
      return pathArray;
    },
  },
  mounted() {},
  methods: {
    findPath(val) {
      if (this.data[val]) {
        return this.data[val][this.language];
      }
      if (!this.arr.length) {
        let lastPath = val.lastIndexOf("/");
        // when the path is not set correct and the last path is not a name, but that is /
        if (lastPath === val.length - 1) {
          const newPath = val.slice(0, lastPath);
          lastPath = newPath.lastIndexOf("/");
        }
        const dynamicNewPath = val.slice(0, lastPath + 1).concat("?");
        this.arr[0] = val;
        this.arr[1] = dynamicNewPath;
        this.obj[val] = dynamicNewPath;
        if (this.data[dynamicNewPath]) {
          return this.data[dynamicNewPath][this.language];
        } else {
          return false;
        }
      } else {
        const foundObjKey = Object.keys(this.obj).find((item) => {
          return item === val;
        });
        if (foundObjKey) {
          if (this.data[this.obj[foundObjKey]]) {
            return this.data[this.obj[foundObjKey]][this.language];
          } else return false;
        } else {
          let savedLastPath = val.replace(this.arr[0], this.arr[1]);
          this.arr[0] = val;
          if (this.data[savedLastPath]) {
            this.arr[1] = savedLastPath;
            this.obj[val] = savedLastPath;
            return this.data[savedLastPath][this.language];
          } else {
            let lastPath = savedLastPath.lastIndexOf("/");
            if (lastPath === savedLastPath.length - 1) {
              savedLastPath = savedLastPath.slice(0, lastPath);
              lastPath = savedLastPath.lastIndexOf("/");
            }
            const dynamicNewPath = savedLastPath
              .slice(0, lastPath + 1)
              .concat("?");
            this.arr[1] = dynamicNewPath;
            this.obj[val] = dynamicNewPath;
            if (this.data[dynamicNewPath]) {
              return this.data[dynamicNewPath][this.language];
            } else {
              return false;
            }
          }
        }
      }
    },
  },
  watch: {
    route(val) {
      if (val.length === 1) {
        this.show = false;
      } else this.show = true;
    },
  },
};
</script>
<style scoped lang="scss">
@import "Breadcrumb-Custom";
</style>
