<template>
  <v-navigation-drawer
    app
    :right="$vuetify.rtl"
    :mini-variant.sync="mini"
    floating
    mini-variant-width="60"
    width="206"
    class="px-1 py-3 background-image"
    v-model="drawerState"
  >
    <template v-slot:prepend>
      <v-list-item class="mb-2">
        <v-img
          :src="require(`@/assets/image/${logoName}`)"
          contain
          height="30"
          position="top left"
        ></v-img>
      </v-list-item>
    </template>

    <v-list nav dense>
      <v-list-item
        v-for="(item, index) in $t('panel.layout.navigationDrawer')"
        :key="index"
        :to="item.to"
        link
        color="white"
        class="mb-4 menu-item"
        active-class="list-item-bg-color"
        :ripple="false"
      >
        <template v-slot:default="{ active }">
          <v-list-item-icon class="pt-1 me-0 me-lg-2">
            <v-icon
              size="22"
              :color="active ? 'primary6' : 'gray12'"
              v-text="item.icon"
            ></v-icon>
          </v-list-item-icon>

          <v-list-item-title
            class="font-regular-14"
            :class="active ? 'primary6--text font-medium-14' : 'gray12--text'"
          >
            {{ item.name }}
          </v-list-item-title>
        </template>
      </v-list-item>

      <v-divider class="mb-3"></v-divider>

      <v-list-item
        color="white"
        class="mb-4 menu-item"
        active-class="list-item-bg-color"
        :ripple="false"
        @click="logout"
      >
        <template v-slot:default="{ active }">
          <v-list-item-icon class="pt-1 me-0 me-lg-2">
            <v-icon size="22" :color="active ? 'primary6' : 'gray12'"
              >$logout</v-icon
            >
          </v-list-item-icon>

          <v-list-item-title
            class="font-regular-14"
            :class="active ? 'primary6--text font-medium-14' : 'gray12--text'"
          >
            {{ $t("panel.layout.logout") }}
          </v-list-item-title>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { UserInfoService } from "@/services";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "navigation-drawer",
  components: {},
  props: [],
  data: () => ({}),
  computed: {
    ...mapGetters({
      drawer: "drawer/GET_DRAWER",
    }),
    mini() {
      let isMini = false;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          isMini = true;
          break;
        case "sm":
          isMini = true;
          break;
        case "md":
          isMini = true;
          break;
        case "lg":
          isMini = false;
          break;
        case "xl":
          isMini = false;
          break;
      }
      return isMini;
    },
    drawerState: {
      get() {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return this.drawer;
        } else {
          return true;
        }
      },
      set(val) {
        this.drawerAction(val);
      },
    },
    logoName() {
      return this.$vuetify.breakpoint.lgAndUp ? "logo.svg" : "logo_mini.svg";
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      drawerAction: "drawer/ACTION_DRAWER",
    }),
    logout() {
      const model = {
        refreshToken: localStorage.getItem("refresh_token"),
      };
      UserInfoService.logout(model).then(() => {
        localStorage.setItem("isAuth", "false");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        this.$router.replace("/auth");
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "NavigationDrawer";
</style>
